// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-animations__animate_from_left--o5SFs {\n    transform: translateX(-100vw);\n}\n\n.src-animations__animate_from_right--zlrlM {\n    transform: translateX(100vw);\n}\n\n@keyframes src-animations__slidefromtop--ASI\\+B {\n    from {\n        transform: translateY(-40vw);\n    }\n\n    to {\n        transform: translateY(0);\n    }\n}\n\n.src-animations__animate_from_top--toEwk {\n    animation: 3s src-animations__slidefromtop--ASI\\+B;\n}", "",{"version":3,"sources":["webpack://./src/animations.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,kDAA0B;AAC9B","sourcesContent":[".animate_from_left {\n    transform: translateX(-100vw);\n}\n\n.animate_from_right {\n    transform: translateX(100vw);\n}\n\n@keyframes slidefromtop {\n    from {\n        transform: translateY(-40vw);\n    }\n\n    to {\n        transform: translateY(0);\n    }\n}\n\n.animate_from_top {\n    animation: 3s slidefromtop;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animate_from_left": "src-animations__animate_from_left--o5SFs",
	"animate_from_right": "src-animations__animate_from_right--zlrlM",
	"animate_from_top": "src-animations__animate_from_top--toEwk",
	"slidefromtop": "src-animations__slidefromtop--ASI+B"
};
export default ___CSS_LOADER_EXPORT___;
