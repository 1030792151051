import React from 'react';

import commonStyles from './common.css';

export default function PrivacyTab() {
    return (
        <div className={commonStyles.centered} style={{ marginTop: 30, padding: '0 10px' }}>
            <div style={{ maxWidth: 600, width: 600 }}>
                <div style={{ textAlign: 'center', fontWeight: 600 }}>
                    OUR POLICY
                </div>
                <p>
                    Welcome to xinx.digital!
                </p>
                <p>
                    xinx.digital (“xinxmedia.com”, "xinx.digital", “XINX,” "we", "us" and/or "our") collects information from users of the website <a href="https://xinx.digital/">https://xinx.digital/</a> (the “Site”) and its other online services (together with the Site, the “Services”). 
                </p>
                <p>
                    This Privacy Policy is designed to inform you about the types of information that we gather about you, and how we may use that information including personally identifiable data (“Personal Data”) and other information that is collected from visitors to the Site, whether we disclose it to anyone, and the choices you have regarding our use of that information.
                </p>
                <p>
                    <strong>
                        By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. 
                    </strong>
                </p>
                <p>
                    <strong>1. Personal Data That You Provide Through the Services</strong><br />
                    When you register or communicate with xinx.digital, you share a limited amount of information in connection with such processes, including but not limited to your name, email address, phone number, address, and other registration information. XINX will process personal information collected from you fairly and lawfully and will not use such personal information for purposes that are incompatible with the purposes it was requested and received. For example, XINX will use your personal information to help us to improve our website and your online experience, to provide you with information about our products and services, to provide you with personalized services, for customer support purposes, and as otherwise allowed by law.
                </p>
                <p>
                    When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below:
                </p>
                <p>
                    <u>Non-Identifiable Data:</u><br />
                    When you interact with XINX through the Services, we receive and store certain personally non-identifiable information. Such information, which is collected passively using various technologies, cannot presently be used to specifically identify you. XINX may store such information itself or such information may be included in databases owned and maintained by XINX affiliates, agents, or service providers. The Services may use such information and pool it with other information to track, for example, the total number of visitors to our Site, the number of visitors to each page of our Site, and the domain names of our visitors' Internet service providers. It is important to note that no Personal Data is available or used in this process.
                </p>
                <p>
                    XINX aims to provide resources for users. To help us better understand how our Users act, and for the purpose of improving functionality, the Site may collect IP addresses. User actions would be aggregated both to improve our Services, and to create reports to share with companies. 
                </p>
                <p>
                    In operating the Services, we may use a technology called "cookies." A cookie is a piece of information that the computer that hosts our Services gives to your browser when you access the Services. Our cookies help provide additional functionality to the Services and help us analyze Services usage more accurately. For instance, our Site may set a cookie on your browser that allows you to access the Services without needing to remember and then enter a password more than once during a visit to the Site. Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your personal computer or mobile device when you go offline; session cookies are deleted as soon as you close your web browser. We may use both session and persistent cookies for the purpose of providing the Services. In all cases in which we use cookies, we will not collect Personal Data except with your permission. 
                </p>
                <p>
                    <u>Aggregated Personal Data:</u><br/>
                    In an ongoing effort to better understand and serve the users of the Services, XINX collects information only as described below. This aggregate data does not identify you personally, but may include information such as your device's IP address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, the type of mobile device you use, and other diagnostic data. 
                </p>
                <p>
                    <u>Location Information: </u><br/>
                    We do not explicitly collect your location information, although we are able to approximate your location from your IP address. We do not store the full IP to avoid using it to identify you. We may also use your location information in an aggregate way, as described above in the “Aggregated Personal Data” section. 
                </p>
                <p>
                    <u>Social Networking Services: </u><br/>
                    You may have the option of posting your activities to Social Networking Services when you access content through the Services (for example, you may post to Facebook that you performed an activity with Us). You acknowledge that if you choose to use this feature, your friends, followers and subscribers on any Social Networking Services you have enabled will be able to view such activity. 
                </p>
                <p>
                    Our Site currently does not respond to “Do Not Track” (DNT) signals and operates as described in this Privacy Policy whether or not a DNT signal is received. If we do so in the future, we will describe how we do so in this Privacy Policy.
                </p>
                <p>
                    <strong>2. Our Use of Your Personal Data and Other Information</strong><br/>
                    XINX uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services, to monitor your use of such services, and to provide other users with such information on varying degrees of consenting interactions. XINX may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. 
                </p>
                <p>
                    <strong>3. Our Disclosure of Your Personal Data and Other Information</strong><br/>
                    There are certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:
                </p>
                <p>
                    <i>Legal Requirements:</i> XINX may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of XINX, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
                </p>
                <p>
                    <strong>4. Your Choices</strong><br/>
                    You can visit the Site without providing any Personal Data. If you choose not to provide any Personal Data, you may not be able to use certain Services. For example, you may not be able to communicate with us through the Site. 
                </p>
                <p>
                    <i>European Union Clients:</i><br/>
                    If you are a Client in the European Union, and you would like to exercise one of the rights provided by the General Data Protection Regulation (“GDPR”) or otherwise inquire about how your Personal Data is handled under the Services, please contact your Professional, who is the Controller of your Personal Data under the GDPR.
                </p>
                <p>
                    <strong>5. Exclusions</strong><br/>
                    This Privacy Policy does not apply to any Personal Data collected by XINX other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to XINX through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, "Unsolicited Information"). All Unsolicited Information shall be deemed to be non-confidential and XINX shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
                </p>
                <p>
                    <strong>6. Children</strong><br/>
                    XINX does not normally collect the personal information of children under the age of 18. If we do, we require verifiable parental consent, prior to collection. If we learn that we have collected children's personal information without parental consent, we will delete it from our systems.
                </p>
                <p>
                    <strong>7. Links to Other Web Sites</strong><br/>
                    This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by XINX (the "Third Party Sites"). The policies and procedures described here do not apply to the Third-Party Sites. The links from the Services do not imply that XINX endorses or has reviewed the Third-Party Sites. We suggest contacting those sites directly for information on their privacy policies.
                </p>
                <p>
                    <strong>8. Security</strong><br/>
                    XINX takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any Personal Data to XINX via the Internet.
                </p>
                <p>
                    <strong>9. Processing Your Personal Data</strong><br/>
                    The service providers we use may have access to your Personal Data. These third-party vendors collect, store, use, process, and transfer information about your activity on our Service in accordance with their Privacy Policies.
                </p>
                <p>
                    <u>Analytics</u><br/>
                    We may use third-party Service providers to monitor and analyze the use of our Service.
                </p>
                <div style={{ marginLeft: 30 }}>
                    <p>
                        <strong>Google Analytics</strong><br/>
                        Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                    </p>
                    <p>
                        You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
                    </p>
                    <p>
                        For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
                    </p>
                </div>
                <p>
                    <u>Advertising</u><br/>
                    We may use Service Providers to show advertisements to you to help support and maintain Our Service.
                </p>
                <div style={{ marginLeft: 30 }}>
                    <p>
                        <strong>Google AdSense &amp; DoubleClick Cookie</strong><br/>
                        Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.<br/>
                        You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: <a href="http://www.google.com/ads/preferences/" target="_blank">http://www.google.com/ads/preferences/</a>
                    </p>
                    <p>
                        <strong>Google Ads (AdWords)</strong><br/>
                        Google Ads (AdWords) remarketing service is provided by Google Inc.<br/>
                        You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads" target="_blank">http://www.google.com/settings/ads</a>
                    </p>
                    <p>
                        Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
                    </p>
                    <p>
                        For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
                    </p>
                </div>
                <p>
                    <u>Email Marketing</u><br/>
                    We may use your Personal Data to contact you with newsletters, marketing, or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
                </p>
                <p>
                    We may use Email Marketing Service Providers to manage and send emails to you.
                </p>
                <div style={{ marginLeft: 30 }}>
                    <p>
                        <strong>Mailchimp</strong><br/>
                        Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC. For more information on the privacy practices of Mailchimp, please visit their Privacy policy: <a href="https://mailchimp.com/legal/privacy/" target="_blank">https://mailchimp.com/legal/privacy/</a>
                    </p>
                </div>
                <p>
                    <u>Affiliate Programs</u><br />
                    Our Services may contain links to products and services offered by third party websites, such as Amazon.com. If you click on those links we will use data collected about your activity on our site to direct you to the third party site. We and the third party may collect data to show us that you have clicked on the link and whether you purchased any products or services. We may receive a commission from the third party if you link to their site from a future site and purchase goods and services from them.
                </p>
                <p>
                    <u>Payments</u><br />
                    We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).
                </p>
                <p>
                    We will not store or collect your payment card details. That information is provided directly to Our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                </p>
                <div style={{ marginLeft: 30 }}>
                    <p>
                        <strong>Stripe</strong><br/>
                        All prices for Services are in U.S. Dollars. Our payment card processing systems and security capabilities comply with PCI standards. Stripe's Privacy Policy can be viewed at <a href="https://stripe.com/privacy" target="_blank">https://stripe.com/privacy</a>
                    </p>
                </div>
                <p>
                    <strong>10. Other Terms and Conditions</strong><br/>
                    Your access to and use of the Services is subject to the Terms of Use: <a href="http://xinx.digital/TermsofUse/" target="_blank">http://xinx.digital/TermsofUse/</a>.
                </p>
                <p>
                    <strong>11. Changes to XINX's Privacy Policy</strong><br/>
                    The Services and our business may change from time to time. As a result, at times it may be necessary for XINX to make changes to this Privacy Policy. XINX reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide any Personal Data. This Privacy Policy was last updated on the date indicated below. your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
                </p>
                <p>
                    <strong>12. GDPR Privacy</strong><br/>
                    <u>Legal Basis for Processing Personal Data under GDPR</u><br/>
                    We may process Personal Data under the following conditions:<br/>
                    <ul>
                        <li>Consent: You have given your consent for processing Personal Data for one or more specific purposes.</li>
                        <li>Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof.</li>
                        <li>Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.</li>
                        <li>Vital interests: Processing Personal Data is necessary in order to protect your vital interests or of another natural person.</li>
                        <li>Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.</li>
                        <li>Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.</li>
                    </ul>
                </p>
                <p>
                    In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                </p>
                <p>
                    <u>Your Rights under the GDPR</u><br/>
                    The Company undertakes to respect the confidentiality of your Personal Data and to guarantee you can exercise your rights.<br/>
                    You have the right under this Privacy Policy, and by law if you are within the EU, to:<br/>
                    <ul>
                        <li>Request access to your Personal Data. The right to access, update or delete the information We have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact Us to assist you. This also enables you to receive a copy of the Personal Data We hold about you</li>
                        <li>Request correction of the Personal Data that We hold about you. You have the right to have any incomplete or inaccurate information We hold about you corrected.</li>
                        <li>Object to processing of your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about your particular situation, which makes you want to object to our processing of your Personal Data on this ground. You also have the right to object where We are processing your Personal Data for direct marketing purposes.</li>
                        <li>Request erasure of your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.</li>
                        <li>Request the transfer of your Personal Data. We will provide to you, or to a third-party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which you initially provided consent for Us to use or where We used the information to perform a contract with you.</li>
                        <li>Withdraw your consent. You have the right to withdraw your consent on using your Personal Data. If you withdraw your consent, We may not be able to provide you with access to certain specific functionalities of the Service.</li>
                    </ul>
                </p>
                <p>
                    <u>Exercising of Your GDPR Data Protection Rights</u><br/>
                    You may exercise your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask you to verify your identity before responding to such requests. If you make a request, We will try our best to respond to you as soon as possible.<br/>
                    You have the right to complain to a Data Protection Authority about Our collection and use of your Personal Data. For more information, if you are in the European Economic Area (EEA), please contact your local data protection authority in the EEA.<br/>
                </p>
                <p>
                    <strong>13. Access to Information; Contacting XINX</strong><br/>
                    To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
                </p>
                <p>
                    Please also feel free to contact us if you have any questions about XINX's Privacy Policy or the information practices of the Services.
                </p>
                <p>
                    You may contact us as follows: <a href="mailto:leadership@xinx.digital" target="_blank">leadership@xinx.digital</a> or 414 S. Buena Vista Avenue, San Jose, California 95126.
                </p>
                <p>
                    XINX reserves the right to change its practices concerning information gathered from visitors to its Website. 
                </p>
                <p>
                    If such changes occur, this Privacy Policy will be updated. Visitors should check back periodically to confirm the then-current terms of this Policy. This Privacy Policy is not intended to and does not create any contractual or other legal rights in or on behalf of any party.
                </p>
                <p>
                    Last Update: 2022-3-19
                </p>
            </div>
        </div>
    );
}