import React from 'react';

import commonStyles from './common.css';

export default function TermsTab() {
    return (
        <div className={commonStyles.centered} style={{ marginTop: 30, padding: '0 10px' }}>
            <div style={{ maxWidth: 600, width: 600 }}>
                <div style={{ textAlign: 'center', fontWeight: 600 }}>
                    XINX LLC
                </div>
                <div style={{ textAlign: 'center', fontWeight: 600, marginTop: 15 }}>
                    LEGAL TERMS OF USE
                </div>
                <p>
                    <strong>Date of Last Revision: March 19th, 2022</strong>
                </p>
                <p>
                    Welcome to XINX LLC!
                </p>
                <p>
                    Please Read Carefully Before Using This Website: The following Terms of Use (“Terms of Use”) govern your access and use of the XINX LLC website located at <a href="https://xinx.digital/">https://xinx.digital/</a> (the “Site”) and the other online services (“Services”), both of which are operated by XINX, LLC (“XINX” or “we”). By using the Site and/or the Service, you irrevocably agree that such use is subject to these Terms of Use, whether you are simply browsing the Site (a “Visitor”) or registered with an account with XINX, LLC (a “Member”). If you do not agree to these Terms of Use, you may not use the Site or the Service. If you are entering into these Terms of Use on behalf of an entity, you are binding such entity to these Terms of Use, you represent that you have the actual authority to bind such entity to these Terms of Use, and references to “you” mean such entity.
                </p>
                <p>
                    XINX, LLC expressly reserves the right to modify the Terms of Use at any time in its sole discretion by including such alteration and/or modification in these Terms of Use, along with a notice of the effective date of such modified Terms of Use. If a revision meaningfully reduces your rights, we will use reasonable efforts to notify you by, for example sending an email to the billing contact you designate in your User Account (as defined below), through your User Account or in the Service itself. Any continued use by you of the Site or the Service after the posting of such modified Terms of Use shall be deemed to indicate your irrevocable agreement to such modified Terms of Use. Accordingly, if at any time you do not agree to be subject to any modified Terms of Use, you may no longer use the Site or Service. 
                </p>
                <p>
                    Please read these Terms carefully; they contain provisions that affect your rights and responsibilities. By using the service, you are agreeing to these provisions.
                </p>
                <p>
                    <strong>Description of the Service</strong>
                </p>
                <p>
                    The Services of the Site are to provide a vision of our work and a means for communication with XINX, LLC. 
                </p>
                <p>
                    <strong>User Account Registration; Passwords</strong>
                </p>
                <p>
                    a.&nbsp;&nbsp;&nbsp;&nbsp;Account Registration and Use License: You can use the Site to communicate with us without registering for an account by entering your email address and name.
                </p>
                <p>
                    In order to access and use all of the features of the Service, you may be asked to open an account (“User Account”) by registering with xinx.digital. When you register for your User Account you must provide true, accurate, current, and complete information (“Account Information''), and you agree to update the Account Information in order to ensure that it is current. Upon proper registration and opening of a User Account, and subject to all of the terms and conditions of these Terms of Use, XINX hereby grants to you the personal, non-transferable right and license to use the Service until such time as either you or XINX elect to terminate such right in accordance with these Terms of Use.
                </p>
                <p>
                    b.&nbsp;&nbsp;&nbsp;&nbsp;Eligibility: If you open a User Account, as an express condition of being permitted to open such User Account, you represent and warrant that you (i) have the legal capacity (including, without limitation, being of sufficient age) to enter into contracts under the law of the jurisdiction in which you reside, (ii) are not on a list of persons barred from receiving services under U.S. laws (including, without limitation, the Denied Persons List and the Entity List issued by the U.S. Department of Commerce, Bureau of Industry and Security) or other applicable jurisdiction, and (iii) are not a resident of Cuba, Iran, North Korea, Sudan, or Syria. 
                </p>
                <p>
                    c.&nbsp;&nbsp;&nbsp;&nbsp;Passwords: Upon registration on the Site, you may be asked to provide XINX with a password to access your account. You are responsible for maintaining the confidentiality of your password and for all of your activities and those of any third party that occur through your account, whether or not authorized by you. You agree to immediately notify XINX of any suspected or actual unauthorized use of your User Account. You agree that XINX will not under any circumstances be liable for any cost, loss, damages or expenses arising out of a failure by you to maintain the security of your password.
                </p>
                <p>
                    Site Content
                </p>
                <p>
                    a.&nbsp;&nbsp;&nbsp;&nbsp;<u>Xinx.digital Content:</u> The information, materials (including, without limitation, HTML, text, audio, video, white papers, press releases, data sheets, product descriptions, software and FAQs, and other content) available on the Site and/or the Service, excluding Third Party Content (collectively, “Xinx.digital Content”), are the copyrighted works of XINX, and XINX expressly retains all right title and interest in and to the Xinx.digital Content, including, without limitation, all intellectual property rights therein and thereto. Except as expressly permitted in these Terms of Use, any use of the Xinx.digital Content may violate copyright and/or other applicable laws.
                </p>
                <p>
                    b.&nbsp;&nbsp;&nbsp;&nbsp;<u>Third Party Content:</u> In addition to Xinx.digital Content, the Site and/or the Service may contain information and materials provided to XINX by third parties (collectively, “Third Party Content”). Third Party Content is the copyrighted work of its owner, who expressly retains all right, title, and interest in and to the Third Party Content, including, without limitation, all intellectual property rights therein and thereto. In addition to being subject to these Terms of Use, Third Party Content may also be subject to different and/or additional terms of use and/or privacy policies of such third parties. Please contact the appropriate third party for further information regarding any such different and/or additional terms of use applicable to Third Party Content.
                </p>
                <p>
                    c.&nbsp;&nbsp;&nbsp;&nbsp;<u>Trademarks:</u> All trademarks, service marks, and logos included on the Site (“Marks”) are the property of XINX or third parties, and you may not use such Marks without the express, prior written consent of XINX or the applicable third party.
                </p>
                <p>
                    d.&nbsp;&nbsp;&nbsp;&nbsp;<u>Monitoring of Site Content and use of Service:</u> XINX reserves the right, but does not undertake the obligation, to monitor use of the Site and/or the Service, and to investigate and take appropriate legal action against any party that uses the Site in violation of these Terms of Use or applicable law. XINX reserves the right to accept, reject, or modify any Site Content or User Content, but assumes no liability based on its acceptance, rejection, modification, or failure to modify any Site Content or User Content.
                </p>
                <p>
                    e.&nbsp;&nbsp;&nbsp;&nbsp;<u>Copyright Infringement:</u> As a condition of your right to use the Site and the Service, you agree to respect the intellectual property rights of others. Accordingly, if you have the ability to do so, you agree not to upload or post to the Site or the Service any copyrighted materials, trademarks, or other proprietary information belonging to any third party without the prior written consent of the applicable third party. You acknowledge that XINX will terminate your access to the Site and/or the Service if you repeatedly infringe the copyright of third parties. If you believe that your copyrighted work has been illegally uploaded or posted on the Site or the Service, you may send a written notice to XINX at leadership@xinx.digital, and XINX will respond pursuant to its Digital Millennium Copyright Act (“DMCA”) procedure. XINX's DMCA procedure is in accordance with that suggested by DMCA, the text of which can be found at the U.S. Copyright Office web site <a href="http://www.copyright.gov/legislation/dmca.pdf" target="_blank">http://www.copyright.gov/legislation/dmca.pdf</a>. XINX reserves all rights to seek damages and fees associated with infringement and/or fraud.
                </p>
                <p>
                    <strong>Your Use of the Site and Service</strong>
                </p>
                <p>
                    a.&nbsp;&nbsp;&nbsp;&nbsp;<u>Account and Use of Service:</u> You may use your User Account for the Service only in accordance with these Terms of Use and only for lawful purposes. You are responsible for your own communications, including the upload, transmission and posting of information, and are responsible for the consequences of their posting on or through the Service.
                </p>
                <p>
                    b.&nbsp;&nbsp;&nbsp;&nbsp;<u>Cancellation and Termination by You:</u> You are solely responsible for properly canceling your User Account. If you are not a registered User, you cancel services by ceasing to visit the Site. If you have a registered account, you may cancel and unregister by emailing leadership@xinx.digital. 
                </p>
                <p>
                    c.&nbsp;&nbsp;&nbsp;&nbsp;<u>Termination and Suspension by XINX:</u> XINX may terminate your User Account and/or these Terms of Use at any time and for any reason upon notice to you. We may also suspend our Service to you at any time, with or without cause. 
                </p>
                <p>
                    d.&nbsp;&nbsp;&nbsp;&nbsp;<u>Effect of Termination.</u>  Once your User Account is terminated, we may permanently delete your User Account and any or all User Content associated with it. If you do not log in to your User Account for 12 or more months, we may treat your User Account as “inactive” and permanently delete the User Account and all the data associated with it.  Except where an exclusive remedy may be specified in this Agreement, the exercise by either party of any remedy, including termination, will be without prejudice to any other remedies it may have under these Terms of Use. All sections of this Agreement which by their nature should survive termination will survive, including without limitation, accrued rights to payment, use restrictions and indemnity obligations, confidentiality obligations, warranty disclaimers, and limitations of liability.
                </p>
                <p>
                    e.&nbsp;&nbsp;&nbsp;&nbsp;<u>Prohibited Conduct:</u> You agree not to use the Site or the Service for: (i) posting any (1) information which is incomplete, false, inaccurate, or not your own, (2) trade secrets or material that is copyrighted or otherwise owned by a third party unless you have a valid license from the owner which permits you to post it, (3) material that infringes on any other intellectual property, privacy, or publicity right of another, (4) advertisement, promotional materials, or solicitation related to any product or service that is competitive with XINX's products or services, (5) software or programs which contain any harmful code, including, but not limited to, viruses, worms, time bombs, or Trojan horses; (ii) impersonating another person; (iii) engaging in or encouraging conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any city, state, national, or international law or regulation, or which fails to comply with accepted Internet protocol; or (iv) transmitting or transferring (by any means) information or software derived from the Site or the Service to foreign countries or certain foreign nations in violation of US export control laws. 
                </p>
                <p>
                    In addition, you agree not to violate or attempt to violate the security of the Site, the Service, or XINX's system or network security, including, without limitation, the following: (a) accessing data not intended for users of the Site or the Service, or gaining unauthorized access to an account, server, or any other computer system; (b) attempting to probe, scan, or test the vulnerability of a system or network or to breach security or authentication measures; (c) attempting to interfere with the function of the Site, the Service, host, or network, including, without limitation, via means of submitting a virus to the Site, overloading, “flooding,” “mailbombing,” “crashing,” or sending unsolicited e-mail, including promotions and/or advertising of products or services; or (6) importing, uploading, or incorporating any Social Security numbers, passwords, security credentials, or sensitive personal information into XINX's products, services, or server. Violations of the Site's, the Service's or XINX's system or network security may result in civil or criminal liability.
                </p>
                <p>
                    In addition, you agree not to, directly or indirectly: (A) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of the Service or any software, documentation, or data related to or provided with the Service (“Software”); (B) modify, translate, or create derivative works based on the Service or Software; or copy (except for archival purposes), rent, lease, distribute, pledge, assign, or otherwise transfer or encumber rights to the Service or Software; (C) use or access the Service to build or support, and/or assist a third party in building or supporting, products, or services competitive to XINX; (D) remove any proprietary notices or labels from the Service or Software; or (E) otherwise use the Service or Software outside of the scope of the rights expressly granted herein. 
                </p>
                <p>
                    f.&nbsp;&nbsp;&nbsp;&nbsp;<u>Your Data:</u> You will retain ownership of any data, information, or material originated by you that you transmit through the Service ("User Content") that passes through the Service. You shall be solely responsible for the accuracy, quality, content, and legality of User Content, the means by which User Content is acquired, and the transmission of User Content outside of the Service.  You represent and warrant that you have all rights necessary to transmit User Content through the Service and to otherwise have User Content used as part of the Service or as otherwise contemplated herein. 
                </p>
                <p>
                    g.&nbsp;&nbsp;&nbsp;&nbsp;<u>Your Posts:</u>  The Site and Service may include functionality that permits users to post content, images, audio files, text, sample code, or other materials or works in a manner that is intended to be viewed by other users (“Your Posts”), including for review and in forums. You hereby grant to XINX a perpetual, irrevocable, royalty-free, worldwide, non-exclusive right and license, including the right to grant sublicenses to third parties, to use, reproduce, publicly display, publicly perform, prepare derivative works from, and distribute Your Posts for any purpose. In addition, you hereby irrevocably represent and warrant to XINX that (i) you have all necessary power, authority, right, title, and/or licenses to grant to XINX the foregoing right and license; (ii) the posting, submission, and display by you of Your Posts on the Site or Service, and the exercise by XINX of the foregoing license does not and will not (1) violate any applicable law or government regulation, or (2) infringe any right of publicity or invades the privacy of others, or any intellectual property right of any third party; and (iii) none of Your Posts (1) will constitute obscene, pornographic, indecent, profane or otherwise objectionable material, (2) are discriminatory, hateful, or bigoted toward, or abusive of, any group or individual, or (3) are libelous or defamatory.
                </p>
                <p>
                    h.&nbsp;&nbsp;&nbsp;&nbsp;<u>Suggestions:</u> You hereby grant to XINX a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate into the Site, the Service, and/or other XINX offerings any suggestions, enhancement requests, recommendations, or other feedback provided by you to XINX that is related to the Site and/or the Service.
                </p>
                <p>
                    i.&nbsp;&nbsp;&nbsp;&nbsp;<u>Aggregated and/or Anonymized Data.</u>  Notwithstanding anything to the contrary set forth herein or otherwise, XINX will have the right to collect and analyze data and other information relating to the provision, use, or performance of the Site and/or Service and related systems and technologies (including information concerning User Data and data derived therefrom), and to aggregate and/or anonymize all such data and information.  XINX will be free at any time to: (i) use such information and data to improve and enhance XINX's offerings; and (ii) disclose such data in aggregate or other de-identified form in connection with its business.
                </p>
                <p>
                    j.&nbsp;&nbsp;&nbsp;&nbsp;<u>Your Indemnification Obligations:</u> You hereby irrevocably agree to indemnify, defend and hold XINX, its affiliates, directors, officers, employees, and agents harmless from and against any and all loss, costs, damages, liabilities, and expenses (including attorneys' fees) arising out of or related to (i) any third party claim resulting from a breach by you of any of your covenants, representations, or warranties contained in these Terms of Use, and/or (ii) your use of the Site and/or the Service.
                </p>
                <p>
                    k.&nbsp;&nbsp;&nbsp;&nbsp;<u>Your Use of The Service to Send Communications:</u> You acknowledge that (a) you exclusively are responsible for and control the timing, content, and distribution of all telephonic or electronic communications made or initiated to any person or entity in connection with your use of the Service, and (b) any such communications are made or initiated only as a result of your actions.  You further warrant that all telephonic or electronic communications made or initiated in connection with your use of the Service comply with all applicable state and federal laws, including without limitation the Telephone Consumer Protection Act, before you make or initiate any telephonic or electronic communication through the Service. 
                </p>
                <p>
                    l.&nbsp;&nbsp;&nbsp;&nbsp;<u>Export Control:</u> You hereby represent and warrant that (i) you understand and acknowledge that some Site Content or components of the Service may be subject to export, re-export, and import restrictions under applicable law, (ii) you will not use the Site, any Site Content or the Service in a manner that violates the U.S. Export Administration Act of 1979 and the regulations of the U.S. Department of Commerce, and (iii) you are not located in, under the control of, or a national or resident of any country to which the United States has embargoed goods.
                </p>
                <p>
                    <strong>Linked Websites and Services</strong>
                </p>
                <p>
                    The Site and Service may contain links to third party websites and services and you agree that XINX provides links to such websites and services solely as a convenience and has no responsibility for the content or availability of such websites or services, and that XINX does not endorse such websites or services (or any products or other services associated therewith). Your use of such websites and services will be subject to the terms applicable to each such website and service.
                </p>
                <p>
                    <strong>LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                    a.&nbsp;&nbsp;&nbsp;&nbsp;<u>Warranty Disclaimer:</u> EXCEPT AS EXPRESSLY SET FORTH IMMEDIATELY ABOVE, THE SITE, SITE CONTENT AND SERVICE ARE PROVIDED STRICTLY ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND XINX MAKES NO WARRANTY THAT THE SITE, SERVICE, OR SITE CONTENT ARE COMPLETE, SUITABLE FOR YOUR PURPOSE, OR ACCURATE, AND ON BEHALF OF ITSELF AND ITS LICENSORS, XINX HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED, STATUTORY, OR OTHER WARRANTIES WITH RESPECT TO THE SITE, SITE CONTENT, AND SERVICE, OR THE AVAILABILITY OF THE FOREGOING, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. THE ENTIRE RISK AS TO RESULTS OBTAINED THROUGH USE OF THE SITE, SERVICE, AND/OR THE SITE CONTENT RESTS WITH YOU AND XINX MAKES NO REPRESENTATION OR WARRANTY THAT THE AVAILABILITY OF THE SITE AND OR THE SERVICE WILL BE UNINTERRUPTED, OR THAT THE SITE, SERVICE, AND/OR THE SITE CONTENT WILL BE ERROR FREE OR THAT ALL ERRORS WILL BE CORRECTED.
                </p>
                <p>
                    b.&nbsp;&nbsp;&nbsp;&nbsp;<u>Limitation of Liability:</u> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT XINX SHALL NOT BE LIABLE TO YOU FOR ANY (A) INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR STATUTORY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF BUSINESS, LOSS OR PROFITS, LOSS OF REVENUE, LOSS OF DATA, LOSS OF GOODWILL, OR FOR ANY COST OF COVER OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES), EVEN IF XINX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE LEGAL THEORY UNDER WHICH DAMAGES ARE SOUGHT, WHETHER IN BREACH OF CONTRACT OR IN TORT, INCLUDING NEGLIGENCE OR (B) AMOUNTS IN THE AGGREGATE THAT EXCEED THE FEES PAID BY YOU TO XINX HEREUNDER IN THE SIX (6) MONTHS PRECEDING THE DATE THE CLAIM AROSE.
                </p>
                <p>
                    c.&nbsp;&nbsp;&nbsp;&nbsp;<u>Assumption of Risk:</u> As with all situations, there are sometimes unknown individual risks and circumstances that can arise during use of the Site and Services that cannot be foreseen that can influence or reduce results. You understand that any suggestion or recommendation on or through the Site is to be taken at your own risk, and recognizing that there is a rare chance that illness, injury or even death could result, you agree to assume all risks.
                </p>
                <p>
                    <strong>Privacy Policy</strong>
                </p>
                <p>
                    You acknowledge that you have read, understand and agree to XINX's Privacy Policy located at <a href="http://xinx.digital/privacy">http://xinx.digital/privacy</a>, which is hereby incorporated into and made a part of these Terms of Use by this reference.
                </p>
                <p>
                    <strong>Location of the Site and your Use</strong>
                </p>
                <p>
                    XINX operates or controls the operation of this Site and the Service from offices in the United States. In addition, the Site and the Service may be mirrored, and other websites operated or controlled by XINX may be located at various locations inside and outside of the United States. XINX makes no representation or warranty that all of the features of this Site or Service will be available to you outside of the United States, or that they are permitted to be accessed outside of the United States. You acknowledge that you are solely responsible for any decision by you to use of this Site and/or the Service from other locations, and that such use may be subject to, and that you are responsible for, compliance with applicable local laws.
                </p>
                <p>
                    <strong>Notices</strong>
                </p>
                <p>
                    XINX may give notice applicable to its general Service customer base by means of a general notice on the Service portal, and notices specific to you by electronic mail to your e-mail address on record in your User Account.  If you have a dispute with XINX, wish to provide a notice under these Terms of Use, or become subject to insolvency or other similar legal proceedings, you must promptly send written notice to XINX at XINX, Attn: Legal, 414 S. Buena Vista Ave., San Jose, California 95126.
                </p>
                <p>
                    <strong>Agreement to Arbitrate</strong>
                </p>
                <p>
                    You and XINX each agree that any and all disputes or claims that have arisen or may arise between you and XINX relating in any way to or arising out of this or previous versions of these Terms of Service, your use of or access to XINX's Services, or any products or services sold, offered, or purchased through Xinx.digital shall be resolved exclusively through final and binding arbitration, rather than in court. Alternatively, you may assert your claims in small claims court, if your claims qualify and so long as the matter remains in such court and advances only on an individual (nonclass, nonrepresentative) basis. The Federal Arbitration Act governs the interpretation and enforcement of this Agreement to Arbitrate.
                </p>
                <p>
                    <u>Prohibition of Class and Representative Actions and Non-Individualized Relief</u>
                </p>
                <p>
                    YOU AND XINX AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND XINX AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS.
                </p>
                <p>
                    <u>Arbitration Procedures</u>
                </p>
                <p>
                    Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, and court review of an arbitration award is very limited. However, an arbitrator can award the same damages and relief on an individual basis that a court can award to an individual. An arbitrator should apply the terms of these Terms of Use as a court would. All issues are for the arbitrator to decide, except that issues relating to arbitrability, the scope or enforceability of this Agreement to Arbitrate, or the interpretation “Prohibition of Class and Representative Actions and Non-Individualized Relief,” shall be for a court of competent jurisdiction to decide.
                </p>
                <p>
                    The arbitration will be conducted by the American Arbitration Association (“AAA”) under its rules and procedures, including the AAA's Consumer Arbitration Rules (as applicable), as modified by this Agreement to Arbitrate. The AAA's rules are available at www.adr.org or by calling the AAA at 1-800-778-7879 (toll free). The use of the word “arbitrator” in this provision shall not be construed to prohibit more than one arbitrator from presiding over an arbitration; rather, the AAA's rules will govern the number of arbitrators that may preside over an arbitration conducted under this Agreement to Arbitrate.
                </p>
                <p>
                    A party who intends to seek arbitration must first send to the other, by certified mail, a written notice describing the nature of the dispute in reasonable detail (“Notice”). The Notice to XINX should be sent to XINX, Attn: Legal, 414 S. Buena Vista Avenue, San Jose, California 95126. XINX will send any Notice to you to the physical address we have on file associated with your Xinx.digital account; it is your responsibility to keep your physical address up to date. All information called for in the Notice must be provided, including a description of the nature and basis of the claims the party is asserting and the relief sought.
                </p>
                <p>
                    If you and XINX are unable to resolve the claims described in the Notice within 30 days after the Notice is sent, you or XINX may initiate arbitration proceedings. A form for initiating arbitration proceedings is available on the AAA's site at www.adr.org. In addition to filing this form with the AAA in accordance with its rules and procedures, the party initiating the arbitration must mail a copy of the completed form to the opposing party. You may send a copy to XINX at the following address: XINX, Attn: Legal, 414 S. Buena Vista Avenue, San Jose, California 95126. In the event XINX initiates an arbitration against you, it will send a copy of the completed form to the physical address we have on file associated with your Xinx.digital account. Any settlement offer made by you or XINX shall not be disclosed to the arbitrator.
                </p>
                <p>
                    The arbitration hearing shall be held in San Jose, California. If the value of the relief sought is $10,000 or less, you or XINX may elect to have the arbitration conducted by telephone or based solely on written submissions, which election shall be binding on you and XINX subject to the arbitrator's discretion to require an in-person hearing, if the circumstances warrant. In cases where an in-person hearing is held, you and/or XINX may attend by telephone, unless the arbitrator requires otherwise.
                </p>
                <p>
                    The arbitrator will decide the substance of all claims in accordance with applicable law, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users but is bound by rulings in prior arbitrations involving the same Xinx.digital user to the extent required by applicable law. The arbitrator's award shall be final and binding and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
                </p>
                <p>
                    <u>Costs of Arbitration</u>
                </p>
                <p>
                    Payment of all filing, administration, and arbitrator fees will be governed by the AAA's rules, unless otherwise stated in this Agreement to Arbitrate. Any request for payment of fees by XINX should be submitted by mail to the AAA along with your Demand for Arbitration and XINX will make arrangements to pay all necessary fees directly to the AAA. In the event the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse XINX for all fees associated with the arbitration paid by XINX on your behalf that you otherwise would be obligated to pay under the AAA's rules.
                </p>
                <p>
                    <u>Severability</u>
                </p>
                <p>
                    With the exception of any of the provisions in “Prohibition of Class and Representative Actions and Non-Individualized Relief,” if an arbitrator or court decides that any part of this Agreement to Arbitrate is invalid or unenforceable, the other parts of this Agreement to Arbitrate shall still apply. If an arbitrator or court decides that any of the provisions in “Prohibition of Class and Representative Actions and Non-Individualized Relief” are invalid or unenforceable, then the entirety of this Agreement to Arbitrate shall be null and void. 
                </p>
                <p>
                    <u>Future Amendments to the Agreement to Arbitrate</u>
                </p>
                <p>
                    Notwithstanding any provision in these Terms of Service to the contrary, you and we agree that if we make any amendment to this Agreement to Arbitrate (other than an amendment to any notice address or site link provided herein) in the future, that amendment shall not apply to any claim that was filed in a legal proceeding against XINX prior to the effective date of the amendment. The amendment shall apply to all other disputes or claims governed by the Agreement to Arbitrate that have arisen or may arise between you and XINX. We will notify you of amendments to this Agreement to Arbitrate by posting the amended terms on Xinx.digital at least 30 days before the effective date of the amendments and/or by email. If you do not agree to these amended terms, you may close your account within the 30-day period and you will not be bound by the amended terms.
                </p>
                <p>
                    <strong>General</strong>
                </p>
                <p>
                    These Terms of Use constitute the entire agreement and understanding between the parties concerning the subject matter hereof, notwithstanding any different or additional terms that may be contained in the form of purchase order or other document used by you to place orders or otherwise effect transactions hereunder, which such terms are hereby rejected. 
                </p>
                <p>
                    Neither party may assign these Terms of Use without the prior written approval of the other, such approval not to be unreasonably withheld or delayed, provided that such approval shall not be required in connection with an assignment to an affiliate or to a successor to substantially all of such party's assets or business related to these Terms of Use. 
                </p>
                <p>
                    These Terms of Use supersede all prior or contemporaneous discussions, proposals, and agreements between you and XINX relating to the subject matter hereof.
                </p>
                <p>
                    No amendment, modification, or waiver of any provision of these Terms of Use will be effective unless in writing and signed by an authorized representative of both parties. 
                </p>
                <p>
                    If any provision of these Terms of Use is held to be invalid or unenforceable, the remaining portions will remain in full force and effect and such provision will be enforced to the maximum extent possible so as to effect the intent of the parties and will be reformed to the extent necessary to make such provision valid and enforceable. 
                </p>
                <p>
                    No waiver of rights by either party may be implied from any actions or failures to enforce rights under these Terms of Use. 
                </p>
                <p>
                    These Terms of Use are intended to be and are solely for the benefit of XINX and you and do not create any right in favor of any third party.
                </p>
                <p>
                    These Terms of Use will be governed by and construed in accordance with the laws of the State of California, without reference to its conflict of laws principles. 
                </p>
                <p>
                    The Uniform Computer Information Transactions Act will not apply to this Agreement.  
                </p>
                <p>
                    All disputes arising out of or relating to these Terms of Use will be submitted to the exclusive jurisdiction of a court of competent jurisdiction located in Solano, California, and each party irrevocably consents to such personal jurisdiction and waives all objections to this venue.
                </p>
                <p>
                    If you are a California resident, in accordance with <u>Cal. Civ. Code § 1789.3</u>, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, California 95814, or by telephone at (800) 952-5210 (Toll Free).
                </p>
            </div>
        </div>
    );
}