import React, { useContext } from 'react';
import classnames from 'classnames';
import { SizeContext } from '@bucky24/mobile-detect';
import { useNavigate } from 'react-router-dom';

import colors from '../colors.css';
import commonStyles from '../common.css';
import animations from '../animations.css';
import typographies from '../typographies.css';

export default function AnimatedCard({ animated, animate, contentWidth, content, link }) {
    const { isMobile } = useContext(SizeContext);
    const navigate = useNavigate();

    return (<div
        style={{
            position: 'relative',
        }}
    >
        <div
            className={classnames(colors.lightGrayBG, commonStyles.centered)}
            style={{
                width: isMobile ? 230 : 267,
                height: isMobile ? 280 : 267,
                borderRadius: 5,
                alignItems: 'center',
                textAlign: 'center',
                visibility: 'hidden',
                padding: 9
            }}
        ></div>
        <div
            className={classnames(
                colors.lightGrayBG,
                commonStyles.centered,
                !animated && animate === "left" && animations.animate_from_left,
                !animated && animate === "right" && animations.animate_from_right,
            )}
            style={{
                width: isMobile ? 230 : 267,
                height: isMobile ? 280 : 267,
                borderRadius: 5,
                alignItems: 'center',
                textAlign: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                transition: "transform 1s",
                cursor: 'pointer',
                padding: 9,
            }}
            onClick={() => {
                if (link) {
                    if (link.startsWith("/")) {
                        navigate(link);
                    } else {
                        window.open(link, "_blank");
                    }
                }
            }}
        >
            <div className={classnames(typographies.description, colors.black)} style={{ width: contentWidth }}>
                {content}
            </div>
        </div>
    </div>)
}