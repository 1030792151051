// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-typographies__header--wtuGy {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 72px;\n    line-height: 64px;\n}\n\n.src-typographies__description--qEx98 {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 36px;\n    line-height: 42px;\n}\n\n.src-typographies__subheader--58qDd {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 48px;\n    line-height: 64px;\n}\n\n.src-typographies__input--GFZ5- {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 24px;\n}", "",{"version":3,"sources":["webpack://./src/typographies.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".header {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 72px;\n    line-height: 64px;\n}\n\n.description {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 36px;\n    line-height: 42px;\n}\n\n.subheader {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 48px;\n    line-height: 64px;\n}\n\n.input {\n    font-family: \"Suranna\", sans-serif;\n    font-weight: 400;\n    font-size: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "src-typographies__header--wtuGy",
	"description": "src-typographies__description--qEx98",
	"subheader": "src-typographies__subheader--58qDd",
	"input": "src-typographies__input--GFZ5-"
};
export default ___CSS_LOADER_EXPORT___;
