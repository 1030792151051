import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { SizeContext } from '@bucky24/mobile-detect';
import { useNavigate } from 'react-router-dom';

//import ExpandableHex from './ExpandableHex';
//import AppContext from './AppContext';
import AnimatedCard from './components/AnimatedCard';
import SocialGoodDonation from './assets/social_good_donation.png';

import styles from './styles.css';
import typographies from './typographies.css';
import colors from './colors.css';
import commonStyles from './common.css';
import animations from './animations.css';
import BeginAdventure from './components/BeginAdventure';

export default function HomeTab({ changeTab }) {
    const { isMobile } = useContext(SizeContext);
    //const { isMobile, getRatio } = useContext(AppContext);
    //const widthRatio = getRatio(940);
    const [scrollTop, setScrollTop] = useState(0);
    const navigate = useNavigate();

    const content = [
        {
            text: 'Website & App Development',
            width: 186,
            animate: 'left',
            scrollTop: 300,
            scrollTopMobile: 300,
            link: '/website-examples',
        }, 
        {
            text: 'UI/UX Design',
            width: 159,
            animate: 'left',
            scrollTop: 400,
            scrollTopMobile: 500,
        },
        {
            text: 'Digital Marketing',
            width: 176,
            animate: 'left',
            scrollTop: 500,
            scrollTopMobile: 800
        },
        {
            text: 'Logo & Graphic Design',
            width: 119,
            animate: 'left',
            scrollTop: 600,
            scrollTopMobile: 1100,
        },
    ];

    useEffect(() => {
        const method = (e) => {
            setScrollTop(e.target.scrollTop);
        };
        document.getElementById("approot").addEventListener("scroll", method);

        return () => {
            document.getElementById("approot").removeEventListener("scroll", method);
        }
    }, []);

    //console.log(scrollTop);

    return (
        <div className={classnames(colors.darkBlueBG, commonStyles.centeredColumn)} style={{ paddingTop: 120 }}>
            <div
                className={classnames(colors.white, !isMobile && typographies.header, isMobile && typographies.subheader)}
                style={{ textAlign: 'center', width: isMobile && 280 }}
            >
                <div>
                    Expanding our digital world
                </div>
                <div>
                    One dream at at time
                </div>
            </div>
            <div
                style={{
                    marginTop: isMobile ? 80 : 120,
                    marginBottom: isMobile ? 120 : 150,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    flexDirection: isMobile && 'column',
                    gridGap: 15,
                }}
            >
                {content.map((item, index) => {
                    let checkScrollTop = isMobile ? item.scrollTopMobile : item.scrollTop;
                    if (!checkScrollTop) {
                        checkScrollTop = item.scrollTop;
                    }
                    const beginScroll = item.scrollTop && scrollTop >= checkScrollTop;
                    return <AnimatedCard key={`item_holder_${index}`} animated={beginScroll} animate={item.animate} content={item.text} contentWidth={item.width} link={item.link} />;
                })}
            </div>
            <BeginAdventure />
            <div className={commonStyles.centered} style={{ marginBottom: 0 }}>
                <img style={{ width: '100%', maxWidth: 1600 }} src={SocialGoodDonation} />
            </div>
        </div>
    )

    /*const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [dream, setDream] = useState('');

    return (
        <div className={classnames(styles.flex_column, styles.flex_center)}>
            <div className={classnames(typographies.header, colors.gray)} style={{ marginTop: 100 }}>
                Begin your digital adventure
            </div>
            <div style={{ marginTop: 40, textAlign: 'center', width: 800, marginBottom: 50 }} className={classnames(typographies.description, colors.gray)}>
                We create custom digital experiences for your business that put people first. Contact us today to learn what we can design and develop for you!
            </div>
            <div className={classnames(styles.flex_column, styles.flex_center, colors.tealBG)} style={{ width: '100%' }}>
                <div style={{ width: 800, marginTop: 80, marginBottom: 193 }}>
                    <div className={classnames(typographies.subheader, colors.white)}>
                        We partner with you to achieve your vision
                    </div>
                    <input style={{ width: 'calc(100% - 50px)', paddingLeft: 50, marginTop: 60 }} className={classnames(typographies.input, colors.inputGray)} type="text" placeholder="your name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input style={{ width: 'calc(100% - 50px)', paddingLeft: 50, marginTop: 20 }} className={classnames(typographies.input, colors.inputGray)} type="text" placeholder="your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <textarea style={{ width: 'calc(100% - 50px)', height: 130, paddingLeft: 50, marginTop: 20, paddingTop: 20 }} className={classnames(typographies.input, colors.inputGray)} placeholder="the dream" value={dream} onChange={(e) => setDream(e.target.value)}>
                    </textarea>
                    <div className={classnames(typographies.description, colors.white, colors.darkBlueBG)} style={{ marginTop: 40, width: '100%', textAlign: 'center', paddingTop: 20, paddingBottom: 20, cursor: 'pointer' }} onClick={() => {
                        const body = "Name: " + name + "\nEmail: " + email + "\nDream: " + dream;
                        const urlBody = encodeURIComponent(body);
                        window.open('mailto:leadership@xinxmedia.com?subject=XINX%20Inquiry&body=' + urlBody);
                    }}>
                        Let's get it done!
                    </div>
                </div>
            </div>
        </div>
    );*/

    /*return (
        <div className={styles.flex_center}>
            <div
                style={{
                    maxWidth: '940px',
                    flexGrow: 1,
                    padding: '0 10px',
                }}
            >
                <div
                    style={{
                        marginLeft: 129 * widthRatio,
                    }}
                >
                    <div 
                        style={{
                            marginTop: 206 * widthRatio,
                            marginBottom: 206 * widthRatio,
                            maxWidth: 458 * widthRatio,
                        }}
                    >
                        <div 
                            className={classnames(styles.text_standard)}
                            style={{
                                fontSize: 72 * widthRatio,
                                lineHeight: `${64 * widthRatio}px`,
                            }}
                        >
                            Begin your digital adventure
                        </div>
                    </div>
                </div>
                <div
                    className={styles.flex_center}
                    style={{
                        marginTop: isMobile ? '0' : '48px',
                        marginBottom: '100px',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            maxWidth: '1386px',
                            maxWidth: '940px',
                            width: '100%',
                            height: 664 * widthRatio,
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                            }}
                        >
                            <ExpandableHex
                                color="#9ba6b0"
                                onExpanded={() => {
                                    changeTab("staff");
                                }}
                                width={386 * widthRatio}
                            >
                                <div
                                    className={classnames(styles.flex_center, styles.flex_column)}
                                    style={{
                                        marginBottom: 28 * widthRatio,
                                    }}
                                >
                                    <div 
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 57 * widthRatio,
                                            lineHeight: `${48 * widthRatio}px`,
                                            marginLeft: -17 * widthRatio,
                                        }}
                                    >
                                        our
                                    </div>
                                    <div
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 70 * widthRatio,
                                            lineHeight: `${24 * widthRatio}px`,
                                        }}
                                    >
                                        team
                                    </div>
                                </div>
                            </ExpandableHex>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                left: '0',
                                top: 151 * widthRatio,
                            }}
                        >
                            <ExpandableHex
                                color="#34495E"
                                onExpanded={() => {
                                    changeTab("skills");
                                }}
                                width={534 * widthRatio}
                            >
                                <div
                                    className={classnames(styles.flex_center, styles.flex_column)}
                                    style={{
                                        marginBottom: 22 * widthRatio,
                                    }}
                                >
                                    <div
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 80 * widthRatio,
                                            lineHeight: `${80 * widthRatio}px`,
                                            marginLeft: -30 * widthRatio,
                                        }}
                                    >
                                        your
                                    </div>
                                    <div 
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 66 * widthRatio,
                                            lineHeight: `${40 * widthRatio}px`,
                                        }}
                                    >
                                        digital presence
                                    </div>
                                </div>
                            </ExpandableHex>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                right: 68 * widthRatio,
                                bottom: '0',
                            }}
                        >
                            <ExpandableHex
                                color="#1B6D69"
                                onExpanded={() => {
                                    changeTab("contact");
                                }}
                                width={293 * widthRatio}
                            >
                                <div
                                    className={classnames(styles.flex_center, styles.flex_column)}
                                    style={{
                                        marginBottom: 10 * widthRatio,
                                    }}
                                >
                                    <div 
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 60 * widthRatio,
                                            marginLeft: 52 * widthRatio,
                                            lineHeight: `${60 * widthRatio}px`,
                                        }}
                                    >
                                        get it
                                    </div>
                                    <div
                                        className={classnames(styles.color_secondary, styles.text_standard, styles.text_header2)}
                                        style={{
                                            fontSize: 70 * widthRatio,
                                            lineHeight: `${40 * widthRatio}px`,
                                        }}
                                    >
                                        done.
                                    </div>
                                </div>
                            </ExpandableHex>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )*/
}