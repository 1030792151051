// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-common__centeredColumn--eq8pC {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.src-common__centered--FzrLE {\n    display: flex;\n    justify-content: center;\n}\n\n.src-common__clickable--iYYVC {\n    cursor: pointer;\n}\n\n.src-common__anchorStandard--Hdvbo {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.src-common__anchorStandard--Hdvbo:hover {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.src-common__anchorStandard--Hdvbo:visited {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.src-common__link--hk98H, .src-common__link--hk98H:hover {\n    text-decoration: none;\n}\n\n.src-common__cardImage--0-LMd {\n    flex-shrink: 0;\n    width: 100px;\n    height: 75px;\n}", "",{"version":3,"sources":["webpack://./src/common.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;CACC,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,qBAAqB;CACrB,cAAc;AACf;;AAEA;CACC,qBAAqB;CACrB,cAAc;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".centeredColumn {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.centered {\n    display: flex;\n    justify-content: center;\n}\n\n.clickable {\n    cursor: pointer;\n}\n\n.anchorStandard {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.anchorStandard:hover {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.anchorStandard:visited {\n\ttext-decoration: none;\n\tcolor: inherit;\n}\n\n.link, .link:hover {\n    text-decoration: none;\n}\n\n.cardImage {\n    flex-shrink: 0;\n    width: 100px;\n    height: 75px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centeredColumn": "src-common__centeredColumn--eq8pC",
	"centered": "src-common__centered--FzrLE",
	"clickable": "src-common__clickable--iYYVC",
	"anchorStandard": "src-common__anchorStandard--Hdvbo",
	"link": "src-common__link--hk98H",
	"cardImage": "src-common__cardImage--0-LMd"
};
export default ___CSS_LOADER_EXPORT___;
