import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import styles from './styles.css';
import Header from './Header';
import HomeTab from './HomeTab';
import { AppProvider } from './AppContext';
import { setQuery, useQuery } from './hooks';
import Footer from './Footer';
import PrivacyTab from './PrivacyTab';
import TermsTab from './TermsTab';
import Examples from './pages/Examples';

const tabColors = {
	staff: "#9ba6b0",
	skills: "#34495E",
	contact: "#1B6D69",
};

export default function App() {
	const bodyRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	const isHome = location.pathname === '/';

	useEffect(() => {
		if (isHome) {
			bodyRef.current.scrollTop = Math.min(bodyRef.current.scrollTop, window.innerHeight * 0.75);
		} else {
			bodyRef.current.scrollTop = 0;
		}
	}, [location.pathname]);

	return(<div className={styles.appRoot} ref={bodyRef} id="approot">
		<AppProvider>
			<Header
				onClick={() => {
					navigate('/');
				}}
				pinHeight={!isHome}
				backgroundColor={tabColor}
				clickable={!isHome}
			/>
			<Routes>
				<Route path="/" element={<HomeTab />} />
				<Route path="/website-examples" element={<Examples />} />
				<Route path="/privacy" element={<PrivacyTab />} />
				<Route path="/TermsofUse" element={<TermsTab />} />
			</Routes>
			<Footer />
		</AppProvider>
	</div>);

	const query = useQuery();
	const [page, setPage] = useState("home");
	//const bodyRef = useRef();

	useEffect(() => {
		let newPage = query.page;
		if (!newPage || newPage === "") {
			newPage = "home";
		}
		setPage(newPage);
	}, [query.page]);

	useEffect(() => {
		// set initial tab based on url
		const pathname = window.location.pathname.toLowerCase();
		if (pathname === '/privacy') {
			setPage('privacy');
		} else if (pathname === "/termsofuse") {
			setPage("terms");
		}
	}, []);

	const changePage = (newPage) => {
		setQuery({
			...query,
			page: newPage,
		});
		setPage(newPage);
		if (bodyRef.current) {
			// we want to scroll to the bottom of the video. That means since video is 75% of page,
			// we need to scroll to 75% of the page. Unless this is home, in which case we just scroll to top

			if (newPage !== "home") {
				bodyRef.current.scrollTop = Math.min(bodyRef.current.scrollTop, window.innerHeight * 0.75);
			} else {
				bodyRef.current.scrollTop = 0;
			}
		}
	};

	const tabColor = tabColors[page];

	return (<div className={styles.appRoot} ref={bodyRef} id="approot">
		{/*<AppProvider>
			<Header
				onClick={() => {
					changePage("home");
				}}
				pinHeight={page !== "home"}
				backgroundColor={tabColor}
				clickable={page !== "home"}
			/>
			<div className={styles.body_outer}>
				{page === "home" && (
					<HomeTab changeTab={changePage} />
				)}
				{page === "staff" && (
					<StaffTab />
				)}
				{page === "skills" && (
					<SkillsTab />
				)}
				{page === "contact" && (
					<ContactTab />
				)}
			</div>
			{page !== "home" && <HomeFab onClick={() => changePage("home") } />}
		</AppProvider>*/}
		<AppProvider>
			<Header
				onClick={() => {
					changePage("home");
				}}
				pinHeight={page !== "home"}
				backgroundColor={tabColor}
				clickable={page !== "home"}
			/>
			<div className={styles.body_outer}>
				{page === "home" && (
					<HomeTab changeTab={changePage} />
				)}
				{page === "privacy" && (
					<PrivacyTab changeTab={changePage} />
				)}
				{page === "terms" && (
					<TermsTab changeTab={changePage} />
				)}
			</div>
			<Footer />
		</AppProvider>
		{/*<Home />*/}
		{/*<img
			src={XinxLogo}
			style={{
				maxHeight: '100vh',
				objectFit: 'cover',
			}}
		/>*/}
	</div>);
}