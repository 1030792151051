import React, { useContext } from 'react';
import { SizeContext } from '@bucky24/mobile-detect';
import classnames from 'classnames';

import commonStyles from '../common.css';
import colors from '../colors.css';
import typographies from '../typographies.css';

export default function BeginAdventure() {
    const { isMobile } = useContext(SizeContext);

    return (
        <a className={commonStyles.link} href="https://calendly.com/robbertthecoderdude/15-minute-consultation">
            <div
                className={classnames(colors.white, !isMobile && typographies.header, isMobile && typographies.subheader)}
                style={{ textAlign: 'center', width: isMobile && 280, textDecoration: 'underline', marginBottom: 120 }}
            >
                Begin your digital adventure
            </div>
        </a>
    );
}