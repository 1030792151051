import { useEffect, useState } from 'react';

export function useQuery() {
    const [query, setQuery] = useState({});

    const updateQuery = () => {
        const search = window.location.search.substr(1);
        const items = search.split("&");
        const newQuery = items.reduce((obj, item) => {
            const [key, value] = item.split("=");
            return {
                ...obj,
                [key]: decodeURIComponent(value),
            };
        }, {});
        setQuery(newQuery);
    };

    useEffect(() => {
        window.addEventListener("popstate", updateQuery);
        updateQuery();

        return () => {
            window.removeEventListener("popstate", updateQuery);
        }
    }, []);

    return query;
}

export function setQuery(newQuery) {
    const queryList = Object.keys(newQuery).map((key) => {
        const value = encodeURIComponent(newQuery[key]);

        if (!key || key === '') {
            return null;
        }

        return `${key}=${value}`;
    }).filter((key) => !!key);

    const queryStr = queryList.join("&");
    history.pushState(null, null, "?" + queryStr);
}