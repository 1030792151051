import React, { useEffect, useMemo, useRef, useState } from 'react';

import { MOBILE } from './constants'

const AppContext = React.createContext();
export default AppContext;

export function AppProvider({ children }) {
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const isMobileRef = useRef(false);

    const resize = () => {
        const isMobile = window.innerWidth < MOBILE;
        setIsMobile(isMobile);
        setWidth(window.innerWidth);
        isMobileRef.current = isMobile;
    }

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize()

        return () => {
            window.removeEventListener("resize", resize);
        }
    }, []);

    const value = useMemo(() => {
        return {
            isMobile,
            width,
            isMobileRef,
            getRatio: (maxWidth) => {
                return Math.min(maxWidth, window.innerWidth) / maxWidth;
            }
        };
    }, [isMobile, width]);

    return (
        <AppContext.Provider
            value={value}
        >
            {children}
        </AppContext.Provider>
    )
}