import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import AnimatedCard from '../components/AnimatedCard';
import { SizeContext } from '@bucky24/mobile-detect';

import colors from '../colors.css';
import commonStyles from '../common.css';
import BeginAdventure from '../components/BeginAdventure';

const examples = [
    {
        name: 'Absolute Engagement',
        link: 'https://absoluteengagement.com/',
        scrollTopMobile: 0,
        scrollTop: 200,
    },
    {
        name: 'Blackbird\'s Cookies',
        link: 'https://www.blackbirdscookies.com',
        scrollTopMobile: 100,
        scrollTop: 200,
    },
    {
        name: 'Warm Hearth Health',
        link: 'https://www.warmhearth.net',
        scrollTopMobile: 400,
        scrollTop: 200,
    },
    {
        name: 'Sustain Our Shelters',
        link: 'http://sustainourshelters.org',
        scrollTopMobile: 700,
        scrollTop: 200,
    },
    {
        name: 'Scanimals',
        link: 'https://scanimals.io/sfacc',
        scrollTopMobile: 1000,
        scrollTop: 200,
    },
    {
        name: "Momentum For Health",
        link: "https://momentumforhealth.org/",
    },
    {
        name: "Girl Talk",
        link: "https://www.girltalk.me/",
    },
];

export default function Examples() {
    const [scrollTop, setScrollTop] = useState(0);
    const { isMobile } = useContext(SizeContext);

    //console.log(scrollTop);

    useEffect(() => {
        const method = (e) => {
            setScrollTop(e.target.scrollTop);
        };
        document.getElementById("approot").addEventListener("scroll", method);

        return () => {
            document.getElementById("approot").removeEventListener("scroll", method);
        }
    }, []);

    return <div className={classnames(colors.darkBlueBG, colors.white)}>
        <div className={commonStyles.centered} style={{ marginBottom: 120 }}>
            <div className={commonStyles.centeredColumn} style={{
                gap: 15,
                marginTop: 15,
                marginBottom: 15,
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'center',
                flexWrap: 'wrap',
                flexDirection: isMobile ? 'column' : 'row',
            }}>
                {examples.map((item) => {
                    let checkScrollTop = isMobile ? item.scrollTopMobile : item.scrollTop;
                    if (checkScrollTop === undefined) {
                        checkScrollTop = item.scrollTop;
                    }
                    const beginScroll = checkScrollTop !== undefined && scrollTop >= checkScrollTop;

                    return <AnimatedCard animated={false} content={item.name} link={item.link} />
                })}
            </div>
        </div>
        <BeginAdventure />
    </div>
}