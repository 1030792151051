// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@5.0.1_webpack@5.18.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-colors__gray--cnqd8 {\n    color: #212837;\n}\n\n.src-colors__tealBG--DIhZ6 {\n    background-color: #5E899B;\n}\n\n.src-colors__white--XW3uP {\n    color: #fff;\n}\n\n.src-colors__inputGray--aBDkG {\n    color: #45464F;\n}\n\n.src-colors__darkBlueBG--F9ACg {\n    background-color: #20354B;\n}\n\n.src-colors__lightGray--Vc\\+5- {\n    color: #F6F4F1;\n}\n\n.src-colors__lightGrayBG--KIz9R {\n    background-color: #F6F4F1;\n}\n\n.src-colors__black--tA5gg {\n    color: #000;\n}", "",{"version":3,"sources":["webpack://./src/colors.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".gray {\n    color: #212837;\n}\n\n.tealBG {\n    background-color: #5E899B;\n}\n\n.white {\n    color: #fff;\n}\n\n.inputGray {\n    color: #45464F;\n}\n\n.darkBlueBG {\n    background-color: #20354B;\n}\n\n.lightGray {\n    color: #F6F4F1;\n}\n\n.lightGrayBG {\n    background-color: #F6F4F1;\n}\n\n.black {\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gray": "src-colors__gray--cnqd8",
	"tealBG": "src-colors__tealBG--DIhZ6",
	"white": "src-colors__white--XW3uP",
	"inputGray": "src-colors__inputGray--aBDkG",
	"darkBlueBG": "src-colors__darkBlueBG--F9ACg",
	"lightGray": "src-colors__lightGray--Vc+5-",
	"lightGrayBG": "src-colors__lightGrayBG--KIz9R",
	"black": "src-colors__black--tA5gg"
};
export default ___CSS_LOADER_EXPORT___;
