import React, { useContext, useEffect, useRef, useState } from 'react';
import { SizeContext } from '@bucky24/mobile-detect';
import classnames from 'classnames';

import HeaderVideo from './assets/header_video.mp4';
import Logo from './assets/logo.svg';

import styles from './styles.css';

export default function Header({ clickable, onClick, pinHeight, backgroundColor }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [videoHeight, setVideoHeight] = useState(0);
    const [left, setLeft] = useState(0);
    const node = useRef();
    const { isMobile } = useContext(SizeContext);
    const [logoBot, setlogoBot] = useState(0);

    const resize = () => {
        if (!node.current) {
            return;
        }

        let width = node.current.getBoundingClientRect().width;
        const aspectRatio = 0.564;
        let height = width * aspectRatio;
        let videoHeight = height;

        if (!isMobile && pinHeight) {
            // instead of height based on width, width based on height
            height = window.innerHeight * 0.75;
            //width = height / aspectRatio
        }

        if (videoHeight < height) {
            videoHeight = height;
            width = videoHeight / aspectRatio;
        }

        setWidth(width);
        setHeight(height);
        setVideoHeight(videoHeight);

        const extraWidth = window.innerWidth - width;
        const newLeft = extraWidth / 2;
        setLeft(newLeft);

        window.requestAnimationFrame(() => {
            // we don't want the logo to dip below the initial screen
            let logoBot = 32;
            if (node.current) {
                const height = node.current.getBoundingClientRect().height;
                const trueBot = height - logoBot;
                if (trueBot > window.innerHeight) {
                    const diff = height - window.innerHeight;
                    logoBot = diff;
                }
            }
            setlogoBot(logoBot);
        })
    }

    useEffect(() => {
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        }
    }, [isMobile, pinHeight]);

    useEffect(() => {
        resize();
    }, [node.current, isMobile, pinHeight]);

    return (
		<div
            className={classnames(styles.header, styles.flex_center, clickable && styles.header_clickable)}
            ref={node}
            onClick={() => {
                if (clickable) {
                    onClick();
                }
            }}
            style={{
                height,
                backgroundColor,
            }}
        >
			<video
                width={width}
                height={videoHeight}
                autoPlay
                muted
                loop
                style={{
                    marginBottom: '-5px',
                    position: 'absolute',
                    top: (videoHeight > height) ? height - videoHeight  + 2 : 0,
                    zIndex: '-1',
                }}
            >
                <source src={HeaderVideo} type="video/mp4"/>
            </video>
            <div
                className={styles.header_image_container}
                style={{
                    width: width,
                }}
            >
                <img
                    src={Logo}
                    className={styles.header_image} 
                    style={{
                        //right: isMobile ? 0 : null,//window.innerWidth * 0.08,
                        bottom: isMobile ? 20 : logoBot + 100,
                        left: isMobile ? 20 : 100,
                        width: isMobile && 120,
                        marginRight: isMobile && 40,
                    }}
                />
            </div>
		</div>
    )
}