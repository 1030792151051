import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SizeProvider } from '@bucky24/mobile-detect';
import { BrowserRouter } from 'react-router-dom';

// for dev only
//const socket = new WebSocket('ws://localhost:8080');
//socket.addEventListener('message', (event) => {
//    window.location.reload();
//});

ReactDOM.render(<SizeProvider mobileSize={600}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</SizeProvider>, document.getElementById('root'));
