import React from 'react';
import classnames from 'classnames';
import { DesktopTablet, Mobile } from '@bucky24/mobile-detect';

import colors from './colors.css';
import commonStyles from './common.css';
import typographies from './typographies.css';

import Visa from './assets/visa_logo.jpg';
import Mastercard from './assets/mastercard.png';
import Discover from './assets/discover.jpg';

export default function Footer() {
    const content = [
        {
            text: 'privacy policy',
            link: "/privacy",
        },
        {
            text: 'contact@xinx.digital',
            email: 'contact@xinx.digital',
        },
        {
            text: 'terms & conditions',
            link: "/TermsofUse",
        },
    ];

    const createItem = (item, index) => {
        const content = (
            <div
                key={`footer_item_${index}`}
                className={classnames(commonStyles.clickable, typographies.input)}
                onClick={() => {
                    if (item.email) {
                        window.open('mailto:' + item.email);
                    }
                    //nothing for now
                }}
                style={{
                    width: 285,
                    flexShrink: 1,
                    textAlign: 'center',
                }}
            >
                {item.text}
            </div>
        );

        if (item.email) {
            return (
                <a className={commonStyles.anchorStandard} href={`mailto: ${item.email}`} target="_blank">{content}</a>
            );
        } else if (item.link) {
            return (
                <a className={commonStyles.anchorStandard} href={item.link} target="_blank">{content}</a>
            );
        }

        return content;
    }

    return (
        <div className={classnames(colors.lightGrayBG, commonStyles.centered)}>
            <DesktopTablet>
                <div style={{ marginTop: 60, maxWidth: '100%', width: 885, marginBottom: 84 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {content.map((item, index) => {
                            return createItem(item, index);
                        })}
                    </div>
                    <div className={classnames(typographies.input, commonStyles.centered)}>
                        San Jose, CA 95126
                    </div>
                    {/*<div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={Visa} className={commonStyles.cardImage} />
                        <img src={Mastercard} className={commonStyles.cardImage} />
                        <img src={Discover} className={commonStyles.cardImage} />
                    </div>*/}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a href='http://www.greengeeks.com' alt='GreenGeeks' rel='nofollow'><img src='https://ads.greengeeks.com/Green_4.png' border='0' style={{ width: 226 }} alt=''/></a>
                    </div>
                </div>
            </DesktopTablet>
            <Mobile>
                <div className={commonStyles.centeredColumn} style={{ marginTop: 60, width: '100%', marginBottom: 112 }}>
                    {content.map((item, index) => {
                        if (!item.email) {
                            return;
                        }
                        
                        return createItem(item, index);
                    })}
                    <div className={classnames(typographies.input, commonStyles.centered)} style={{ marginBottom: 20 }}>
                        San Jose, CA 95125
                    </div>
                    {content.map((item, index) => {
                        if (item.email) {
                            return;
                        }
                        
                        return createItem(item, index);
                    })}
                   {/*<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={Visa} className={commonStyles.cardImage} />
                        <img src={Mastercard} className={commonStyles.cardImage} />
                        <img src={Discover} className={commonStyles.cardImage} />
                    </div>*/}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <a href='http://www.greengeeks.com' alt='GreenGeeks' rel='nofollow'><img src='https://ads.greengeeks.com/Green_4.png' style={{width: 226 }} border='0' alt=''/></a>
                    </div>
                </div>
            </Mobile>
        </div>
    );
}